$('.hamburger').click(function() {
  $(this).toggleClass('is-active');
  $('.mobile-menu').toggleClass('open');
  $('.tagline').toggleClass('open');
  $('html').toggleClass('fixed');
});

$('.lead-toggle').click(function() {
  $('.lead-nav-inner-mobile').slideToggle();
  $(this).toggleClass('turn');
});

$(document).click(function(event) {
  $target = $(event.target);
  if(!$target.closest('#lead-nav').length) {
    $('.lead-nav-inner-mobile').slideUp();
    $('.lead-toggle').removeClass('turn');
  }
});

$('.youth-toggle').click(function() {
  $('.youth-nav-inner-mobile').slideToggle();
  $(this).toggleClass('turn');
});

$(document).click(function(event) {
  $target = $(event.target);
  if(!$target.closest('#youth-nav').length) {
    $('.youth-nav-inner-mobile').slideUp();
    $('.youth-toggle').removeClass('turn');
  }
});

$(function() {
  $('select').selectric();
});

$('#search-trigger').click(function() {
  $('#search-modal').addClass('open');
  $('html').addClass('fixed');
});
$('#search-trigger-mobile').click(function() {
  $('#search-modal').addClass('open');
  $('html').addClass('fixed');
});

$('.toggle-search').click(function() {
  $('#search-modal').removeClass('open');
  $('html').removeClass('fixed');
});

$(function() {
  if($('#search-results').length) {
    var url = window.location.href;
    var query = url.substring(url.indexOf("=") + 1);
    query = decodeURIComponent(query);

    $('.query').append(query);
  }
});

$('.filter-select').on('selectric-change', function(event, element, selectric) {
  var filters = []
  $('.filter-select').each(function() {
    filters.push($(this).val());
  });

  $('.serve-item').each(function() {
      var $this = $(this)
      var dataFilters = $this.data('filters');
      var match = true;

            $this.css('display', 'none');

            filters.forEach(function(item) {
        if (dataFilters.indexOf(item) < 0) {
          match = false;
        }
      });

      if (match == true) {
        $this.css('display', 'flex');
      }
  });
});

$(function() {
  $(document).on('click', '#loadMore', function(e) {
    e.preventDefault();

    var $this = $(this);
    var url = $this.attr('href');

    $.ajax({
      type: 'get',
      url: url,
      beforeSend: function() {
        $this.text('Loading...');
      },
      success: function(data) {
        $('#loadMore').parent().remove();
        $('#resources-list').append(data);
      },
      error: function(error) {
        console.log(error);
      }
    })
  });

  $('.subscribe-form').on('submit', function(e) {





  });
});

if($('#leadgen-modal').length > 0) {

  $('#trigger-download-form').on('click', function(e) {
    e.preventDefault();
    $('body').addClass('with-nav');
    $('#leadgen-modal').fadeIn().attr('aria-hidden', 'false');
    $('#leadgen-modal .toggle-form-modal').attr('aria-expanded', 'true');
    $('#form-input-fullName').focus();
  });

  $('#leadgen-modal .overlay, #leadgen-modal .toggle-form-modal').on('click', function(e) {
    e.preventDefault();
    $('body').removeClass('with-nav');
    $('#leadgen-modal').fadeOut().attr('aria-hidden', 'true');
    $('#leadgen-modal .toggle-form-modal').attr('aria-expanded', 'false');
  });

  $(document).on('keyup', function(e) {
    if (e.key === 'Escape') {
      $('body').removeClass('with-nav');
      $('#leadgen-modal').fadeOut().attr('aria-hidden', 'true');
      $('#leadgen-modal .toggle-form-modal').attr('aria-expanded', 'false');
    }
  });
}

$('.leadgen-form').on('submit', function(e) {
  e.preventDefault();

    var data = $(this).serialize();
  var redirect = $(this).data('redirect');

  axios({
      method: 'post',
      url: '',
      data: data
    })
    .then(function(response) {
      window.location.href = redirect;
    })
    .catch(function(error) {
      console.log(error);
    });
});

function getCSRFToken() 
{
  axios({
      method: 'get',
      url: '/actions/site-module/site/get-csrf',
    })
    .then(function(response) {
      $('input[name="CRAFT_CSRF_TOKEN"]').each(function(i, e) {
        $(e).val(response.data.value);
      });
    })
    .catch(function(error) {
      console.log(error);
    });
}

getCSRFToken();